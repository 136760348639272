import * as Styled from './OxTimeSelect.styled';

import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

type TProps = {
    availableHours: TAvailableTime[];
    onTimeSelect: (time: DateTime | string) => void;
    selectedTime?: DateTime;
    allHoursAvailable?: boolean;
    duration?: number;
    startTime?: DateTime;
    endTime?: DateTime;
    onMount?: () => void;
    mode?: EMode;
};

export type TAvailableTime = {
    startsAt: DateTime | string;
    available: boolean;
};

export enum EMode {
    SIMPLE = 'SIMPLE',
    TIME_BASED = 'TIME_BASED'
}

export const OxTimeSelect = ({
    availableHours,
    selectedTime,
    onTimeSelect,
    duration,
    startTime,
    endTime,
    allHoursAvailable,
    onMount,
    mode = EMode.TIME_BASED,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const handleTimeSelect = (time: DateTime | string): void => onTimeSelect(time);

    const formatTime = (time: DateTime | string): string => {
        if (typeof time === 'string') {
            return time;
        }

        return time.setLocale('en').toLocaleString(DateTime.TIME_24_SIMPLE);
    };

    const isSelected = (time: DateTime | string): boolean => {
        if (!selectedTime) return false;

        if (typeof time === 'string') {
            return time === selectedTime;
        }

        return selectedTime.hasSame(time, 'hour') && selectedTime.hasSame(time, 'minutes');
    };

    const isDisabled = (time: DateTime): boolean => {
        return allHoursAvailable
            ? false
            : mode === EMode.TIME_BASED &&
                  !availableHours.find(
                      (item: TAvailableTime) =>
                          time.hasSame(item.startsAt, 'hour') &&
                          time.hasSame(item.startsAt, 'minutes') &&
                          item.available
                  );
    };

    const getTiles = (): DateTime[] => {
        if (availableHours.length === 0 && allHoursAvailable && duration && startTime && endTime) {
            const diff = Math.abs(endTime.diff(startTime, 'minutes').minutes);
            const count = Math.floor(diff / duration) + 1;
            const tiles = [];
            for (let i = 0; i < count; i++) {
                tiles.push(startTime.plus({ minutes: duration * i }));
            }
            return tiles;
        }

        return availableHours.map((item: TAvailableTime) => item.startsAt);
    };

    useEffect(() => {
        onMount && onMount();
    }, []);

    return (
        <Styled.Container
            numberOfOptions={availableHours.length}
            {...props}
            id="appointmentTimeTiles"
        >
            {getTiles().map((time, index) => (
                <Styled.Time
                    key={index}
                    type="button"
                    disabled={isDisabled(time)}
                    selected={isSelected(time)}
                    onClick={(): void => handleTimeSelect(time)}
                >
                    {formatTime(time)}
                </Styled.Time>
            ))}
        </Styled.Container>
    );
};
