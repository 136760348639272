import * as Styled from './OxCalendar.styled';

import Calendar, { OnChangeDateCallback } from 'react-calendar';
import React, { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

type TProps = {
    selectedDate?: DateTime;
    clickableDates?: string[];
    excludedDates?: Date[];
    onDateSelect: (date: DateTime) => void;
    defaultActiveStartDate?: Date;
    disableBeforeDefaultActiveStartDate?: boolean;
    allFutureDaysAllowed?: boolean;
    allPastDaysAllowed?: boolean;
    allFutureWorkdaysAllowed?: boolean;
    onMonthChange?: (date: DateTime) => void;
    minDate?: DateTime;
    maxDate?: DateTime;
};

export const OxCalendar = ({
    onDateSelect,
    selectedDate,
    onMonthChange,
    clickableDates,
    excludedDates,
    allFutureWorkdaysAllowed,
    allFutureDaysAllowed,
    allPastDaysAllowed,
    defaultActiveStartDate,
    disableBeforeDefaultActiveStartDate,
    minDate,
    maxDate,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const [visibleMonth, setVisibleMonth] = useState(
        DateTime.fromJSDate(defaultActiveStartDate).toUTC() || DateTime.utc()
    );
    const handleCalendarChange: OnChangeDateCallback = (e) => {
        onDateSelect(DateTime.fromJSDate(e as Date));
    };

    const onArrowClick = (direction: string): void => {
        switch (direction) {
            case 'next':
                setVisibleMonth(visibleMonth.plus({ months: 1 }));
                break;
            case 'prev':
                setVisibleMonth(visibleMonth.minus({ months: 1 }));
                break;
        }
    };

    useEffect(() => {
        onMonthChange && onMonthChange(visibleMonth);
    }, [visibleMonth]);

    const shouldDisableBasedOnDefaultActiveStartDate = (date: Date): boolean => {
        return disableBeforeDefaultActiveStartDate && date.getTime() < defaultActiveStartDate;
    };

    const shouldDisableBasedOnExcludedDate = (date: Date): boolean => {
        return (
            excludedDates &&
            excludedDates.find((excludedDate: Date) => excludedDate.toString() === date.toString())
        );
    };

    const tileDisabled = ({ date }: { date: Date }): boolean => {
        if (allFutureDaysAllowed && allPastDaysAllowed) return false;

        if (allFutureDaysAllowed || allFutureWorkdaysAllowed) {
            const now = new Date();
            if (allFutureWorkdaysAllowed) {
                return (
                    [0, 6].includes(date.getDay()) ||
                    date.getTime() < now.getTime() ||
                    shouldDisableBasedOnDefaultActiveStartDate(date) ||
                    shouldDisableBasedOnExcludedDate(date)
                );
            }
            return (
                date.getTime() < now.getTime() ||
                shouldDisableBasedOnDefaultActiveStartDate(date) ||
                shouldDisableBasedOnExcludedDate(date)
            );
        } else {
            return !clickableDates?.find((day: string) => {
                const dateDatetime = DateTime.fromJSDate(date);
                const dayDatetime = DateTime.fromISO(day);
                return (
                    (dayDatetime.hasSame(dateDatetime, 'year') &&
                        dayDatetime.hasSame(dateDatetime, 'month') &&
                        dayDatetime.hasSame(dateDatetime, 'day')) ||
                    shouldDisableBasedOnDefaultActiveStartDate(date) ||
                    shouldDisableBasedOnExcludedDate(date)
                );
            });
        }
    };

    return (
        <Styled.Container {...props}>
            <Calendar
                locale="en-UK"
                onChange={handleCalendarChange}
                nextLabel={
                    <Styled.NextArrow onClick={(): void => onArrowClick('next')} name="arrow-1" />
                }
                prevLabel={
                    <Styled.NextArrow
                        onClick={(): void => onArrowClick('prev')}
                        name="arrow-1"
                        rotated
                    />
                }
                next2Label={null}
                prev2Label={null}
                minDetail="month"
                maxDetail="month"
                minDate={minDate?.toJSDate()}
                maxDate={maxDate?.toJSDate()}
                tileDisabled={tileDisabled}
                value={selectedDate?.toJSDate() || null}
                defaultActiveStartDate={defaultActiveStartDate || undefined}
                formatShortWeekday={(locale, date): string =>
                    date.toLocaleDateString(locale, { weekday: 'narrow' })
                }
            />
        </Styled.Container>
    );
};
