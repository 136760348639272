import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxIcon } from '../OxIcon';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { transparentize } from 'polished';

export const Container = styled.div(
    ({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    .react-calendar {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${transparentize(0.5, theme.colors.basic.white)};
      ${fluidSizing([
          { prop: 'max-height', values: [450, 400, 400] },
          { prop: 'padding-left', values: [22, 30, 30] },
          { prop: 'padding-right', values: [22, 30, 30] },
          { prop: 'padding-top', values: [18, 38, 38] },
          { prop: 'padding-bottom', values: [10, 25, 25] }
      ])};
      &__viewContainer,
      &__month-view,
      &__month-view__days,
      &__viewContainer > * {
        display: flex;
        flex: 1;
        max-height: 600px;
      }
      &__month-view > * {
        height: 100%;
        width: 100%;
        & > * {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      &__navigation {
        display: flex;

        ${fluidSizing([
            { prop: 'height', values: [22, 22, 22] },
            { prop: 'margin-bottom', values: [20, 30, 30] }
        ])};
        span {
          ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [14, 14, 16], [18, 18, 22])}
        }
      }
      &__month-view__weekdays {
        border-bottom: 1px solid ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'padding-bottom', values: [5, 10, 10] },
            { prop: 'margin-bottom', values: [10, 20, 20] }
        ])};
      }
      &__month-view__weekdays__weekday {
        text-align: center;
        abbr {
          text-decoration: none;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
        }
      }
      &__month-view__days button {
        overflow: unset !important;
      }
      &__month-view__days__day {
          ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
      }
      &__month-view__days__day--neighboringMonth {
        opacity: 0.3;
      }
      &__tile {
        position: relative;
        &:disabled {
          opacity: 0.3;
        }
        abbr {
          transition: 300ms;
        }
        &:after {
          content: "";
          position: absolute;
          border-radius: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(0.5);
          opacity: 0;
          transition: 300ms;
          ${fluidSizing([
              { prop: 'width', values: [36, 34, 40] },
              { prop: 'height', values: [36, 34, 40] }
          ])};
        }
          &:not(:disabled):hover {
            &:after {
              background-color: ${theme.colors.secondary.variant};
            }
          }
          &:hover,
          &--active {
            &:disabled {
              cursor: default;
            }
            &:after {
              transform: translate(-50%, -50%) scale(1);
              opacity: 1;
            }
            abbr {
              z-index: 2;
              position: relative;
            }
          }
          &--active {
            color: ${theme.colors.basic.white} !important;
            &:after {
              background-color: ${theme.colors.primary.main} !important;
            }
          }
        }
      }
    }
  `
);

export const NextArrow = styled(OxIcon)<{ rotated?: boolean }>(
    ({ theme, rotated }) => css`
        color: ${theme.colors.primary.main};
        ${fluidSizing([{ prop: 'height', values: [14, 14, 14] }])};
        ${rotated &&
        css`
            transform: scale(-1);
        `}

        .react-calendar__navigation__arrow:disabled & {
            color: ${theme.colors.basic.ouronyxLogo};
            cursor: default;
        }
    `
);
