import { EFontWeight, EFonts } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import styled, { css } from 'styled-components';

import { createTextStyle } from 'src/utils/createTextStyle';
import { transparentize } from 'polished';

export const Container = styled.div<{ numberOfOptions: number }>(
    ({ theme, numberOfOptions }) => css`
        height: ${numberOfOptions > 4 ? '50%' : '25%'};
        display: grid;
        ${numberOfOptions < 4 &&
        css`
            flex: 1;
        `}
        grid-template-columns: repeat(${numberOfOptions >= 4 ? 4 : 1}, 1fr);
        grid-auto-rows: 1fr;

        ${fluidSizing([
            { prop: 'column-gap', values: [6, 10, 10] },
            { prop: 'row-gap', values: [6, 10, 10] }
        ])}

        ${theme.breakpoints.up('s')} {
            flex: unset;
            grid-template-columns: repeat(${numberOfOptions >= 4 ? 4 : numberOfOptions}, 1fr);
        }
    `
);

export const Time = styled.button<{
    disabled?: boolean;
    selected?: boolean;
    current?: boolean;
}>(
    ({ theme, disabled, selected, current }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        ${!disabled &&
        css`
            &:hover {
                cursor: pointer;
                background-color: ${theme.colors.secondary.variant};
                ${createTextStyle(
                    EFonts.Montserrat,
                    EFontWeight.SemiBold,
                    [12, 12, 12],
                    [16, 16, 16]
                )}
            }
        `}
        ${!selected &&
        !current &&
        css`
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
            background-color: ${transparentize(0.5, theme.colors.basic.white)};
        `};
        ${disabled &&
        css`
            opacity: 0.3;
            background-color: ${theme.colors.basic.disabledTile};
            &:hover {
                cursor: default;
            }
        `};
        ${selected &&
        css`
            color: ${theme.colors.basic.white} !important;
            background-color: ${theme.colors.primary.main} !important;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
        `};
    `
);
