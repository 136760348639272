import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromises: { [pk: string]: Promise<any | null> } = {};

export const getStripePromiseInstance = (stripePK?: string) => {
    if (!stripePK) return null;

    if (!(stripePK in stripePromises)) {
        stripePromises[stripePK] = loadStripe(stripePK);
    }

    return stripePromises[stripePK];
};
